/**
 * @module Favicons
 * @description
 */
import { getAsset } from '@utils/helpers';

/**
 * @function Favicons
 * @description
 */
const Favicons = () => {
  const siteTitle = 'Autojack21';
  const siteUrl = 'https://autojack.com';
  const faviconPath = `${ getAsset( '/img/favicons/' ) }`;
  const siteDescription = 'The place where you learn how to play the newest and most fun, fast-paced Blackjack game &mdash; Autojack Blackjack.';

  return (
    <>

      { /* PWA Info */ }
      <meta name='application-name' content={ siteTitle } />
      <meta name='apple-mobile-web-app-capable' content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='default' />
      <meta name='apple-mobile-web-app-title' content={ siteTitle } />
      <meta name='mobile-web-app-capable' content='yes' />
      <meta name='msapplication-config' content={ `${ faviconPath }browserconfig.xml` } />
      <meta name='msapplication-tap-highlight' content='no' />
      <meta name="theme-color" content="#333333" />

      <link rel='apple-touch-icon' href={ `${ faviconPath }apple-touch-icon.png` } />
      <link rel='apple-touch-icon' sizes='152x152' href={ `${ faviconPath }apple-touch-icon-ipad.png` } />
      <link rel='apple-touch-icon' sizes='180x180' href={ `${ faviconPath }apple-touch-icon-iphone-retina.png` } />
      <link rel='apple-touch-icon' sizes='167x167' href={ `${ faviconPath }apple-touch-icon-ipad-retina.png` } />

      <link rel='icon' type='image/png' sizes='32x32' href={ `${ faviconPath }favicon-32x32.png` } />
      <link rel='icon' type='image/png' sizes='16x16' href={ `${ faviconPath }favicon-16x16.png` } />
      <link rel='shortcut icon' href={ `${ faviconPath }favicon.ico` } />

      <link rel='manifest' href={ `${ getAsset( '/manifest.json' ) }` } type="application/manifest+json"/>
      <link rel='mask-icon' href={ `${ faviconPath }safari-pinned-tab.svg` } color="#5bbad5" />

      { /* Twitter
      <meta name="twitter:card" content="summary_large_image" />
      <meta name='twitter:url' content={ siteUrl } />
      <meta name='twitter:title' content={ siteTitle } />
      <meta name='twitter:description' content={ siteDescription } />
      <meta name='twitter:image' content={ `${ faviconPath }android-chrome-192x192.png` } /> */ }

      <meta property='og:type' content='website' />
      <meta property="og:title" content="Autojack | Autojack Blackjack" />
      <meta property='og:description' content={ siteDescription } />
      <meta property='og:site_name' content={ siteTitle } />
      <meta property='og:url' content={ siteUrl } />
      <meta property='og:image' content={ `${ siteUrl }${ faviconPath }apple-touch-icon.png` } />
      { /* END PWA Info */ }
    </>
  );
};

export default Favicons;
