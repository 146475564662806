/**
 * @module App
 * @description Our NextJS App
 */
import Head from 'next/head';
import Favicons from '@molecules/Favicons';
import { useEffect } from 'react';
import { ready } from '@/js/utils/events';

import '@/styles/globals.css';
import '@/fonts/icomoon/style.css';

/**
 * @function App
 * @description Our NextJS App
 */
const App = ( { Component, pageProps } ) => {
  /**
   * @function loadJS
   * @description Load our global js
   */
  const loadJS = () => import( '@/js/main.js' ).then( ( module ) => module.default() );

  /**
   * @function useEffect
   * @description Kick off this modules functions
   */
  useEffect( () => {
    ready( loadJS );
  }, [ ] );

  return (
    <>
      <Head>
        <Favicons />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover" />
      </Head>

      <Component { ...pageProps } />
    </>
  );
};

export default App;
